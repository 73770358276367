<template>
  <form id="collect-form">
    <div class="widgetContainer bg-white widgetContainer--scrollable debitPull">
      <div class="navigationHeader">
        <div class="navigationHeader__inner">
          <p class="title">
            {{ $t('RCD_debitPull') }}
          </p>
          <span class="el-icon-close" @click="drawerClose" />
        </div>
      </div>

      <div class="widgetContainer__body">
        <input type="hidden" name="token">
        <div class="debit-pull-group">
          <div class="debit-pull-group--element">
            <label>
              <span>Card number</span>
              <div id="cc-number" class="field" />
            </label>
            <template v-if="vgsErrors && vgsErrors['card_number'] && vgsErrors['card_number'].errorMessages">
              <ul>
                <li v-for="(error) in vgsErrors['card_number'].errorMessages" :key="error">
                  {{ error }}
                </li>
              </ul>
            </template>
          </div>
          <div class="debit-pull-group--element">
            <label>
              <span>Expiry month</span>
              <div id="cc-expiration-date" class="field" />
            </label>
            <template v-if="vgsErrors && vgsErrors['card_exp_date'] && vgsErrors['card_exp_date'].errorMessages">
              <ul>
                <li v-for="(error) in vgsErrors['card_exp_date'].errorMessages" :key="error">
                  {{ error }}
                </li>
              </ul>
            </template>
          </div>
          <div class="debit-pull-group--element">
            <label>
              <span>Expiry year</span>
              <div id="cc-expiration-year" class="field" />
            </label>
            <template v-if="vgsErrors && vgsErrors['card_exp_year'] && vgsErrors['card_exp_year'].errorMessages">
              <ul>
                <li v-for="(error) in vgsErrors['card_exp_year'].errorMessages" :key="error">
                  {{ error }}
                </li>
              </ul>
            </template>
          </div>
          <div class="debit-pull-group--element">
            <label>
              <span>CVC</span>
              <div id="cc-cvc" class="field" />
            </label>
            <template v-if="vgsErrors && vgsErrors['card_cvc'] && vgsErrors['card_cvc'].errorMessages">
              <ul>
                <li v-for="(error) in vgsErrors['card_cvc'].errorMessages" :key="error">
                  {{ error }}
                </li>
              </ul>
            </template>
          </div>
        </div>
      </div>
      <div class="el-drawer__footer">
        <button
          type="submit"
          class="el-button el-button__brand brand width-100">
          {{ $t('next') }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import plaid from '../mixins/plaid';
import {getEnv} from '@/utils/env';

export default {
  mixins: [plaid],
  data() {
    return {
      selectedBank: null,
      vgsErrors: null
    };
  },
  computed: {
    ...mapGetters('account', ['getSelectedAccount']),
    ...mapGetters('contact', ['getSelfContacts', 'getSelectedContactData']),
    ...mapGetters('fund', ['getDebitCardToken']),
    ...mapGetters('user', ['getUserAuthToken', 'getPersonDetails']),
    isFormValid() {
      localStorage.setItem('test', JSON.stringify(this.form));
      return this.selectedBank !== null;
    }
  },
  mounted() {
    if(!window.VGSCollect) {
      const loader = this.showLoader();
      window.addVgsCollectScript(() => {
        this.loadForm();
        loader.close();
      })
    } else {
      this.loadForm();
    }
  },
  created() {
  },
  methods: {
    ...mapActions('contact', ['updateContactDebitcard']),
    loadForm() {
      const vaultId = getEnv('VUE_APP_VGS_VAULT_ID');
      const isProduction = getEnv('VUE_APP_ENV') === 'prod';
      const that = this;
      localStorage.setItem('vault', vaultId);
      localStorage.setItem('isProduction', isProduction ? 'live' : 'sandbox');

      const form = window.VGSCollect.create(vaultId, isProduction ? 'live' : 'sandbox', () => {});

      form.field('#cc-number', {
        type: 'card-number',
        name: 'card_number',
        successColor: getComputedStyle(document.documentElement).getPropertyValue('--success-color'),
        errorColor: getComputedStyle(document.documentElement).getPropertyValue('--color-red'),
        placeholder: 'Card number',
        showCardIcon: true,
        validations: ['required', 'validCardNumberExtended'],
      });

      form.field('#cc-cvc', {
        type: 'card-security-code',
        name: 'card_cvc',
        successColor: getComputedStyle(document.documentElement).getPropertyValue('--success-color'),
        errorColor: getComputedStyle(document.documentElement).getPropertyValue('--color-red'),
        placeholder: 'CVC',
        validations: ['required', 'validCardSecurityCode'],
      });

      form.field('#cc-expiration-date', {
        type: 'number',
        name: 'card_exp_date',
        successColor: getComputedStyle(document.documentElement).getPropertyValue('--success-color'),
        errorColor: getComputedStyle(document.documentElement).getPropertyValue('--color-red'),
        placeholder: '01',
        validations: ['required', '/^([0-9]{2})$/'],
      });

      form.field('#cc-expiration-year', {
        type: 'number',
        name: 'card_exp_year',
        successColor: getComputedStyle(document.documentElement).getPropertyValue('--success-color'),
        errorColor: getComputedStyle(document.documentElement).getPropertyValue('--color-red'),
        placeholder: new Date().getFullYear(),
        validations: ['required', '/^([0-9]{4})$/'],
      });

      document.getElementById('collect-form').addEventListener('submit', (e) => {
        e.preventDefault();

        form.submit('/v1/contact/'+ this.getSelectedContactData?.id +'/debitcard', {
          method: 'PATCH',
          headers: {
            'sd-debitcard-token': this.getDebitCardToken?.debitCardToken,
          },
          data: (formValues) => {
            return {
              debitCard: {
                cardNumber: formValues['card_number'],
                expiryMonth: formValues['card_exp_date'],
                expiryYear: formValues['card_exp_year'],
                cvv: formValues['card_cvc'],
                address: this.getPersonDetails.address
              }
            }
          }
        }, function (status, data) {
          if (status !== 200) {
            if (data.sysMessage.includes('CardAccountNumber Length')) {
              that.$emit('goTo', 'DebitPull-amount', 'replace');
            } else {
              that.apiErrorMessage(`Error: ${status} ${data.message}`);
              that.drawerClose();
            }
          } else {
            that.$emit('goTo', 'DebitPull-amount', 'replace');
          }
        }, (errors) => {
          that.vgsErrors = errors;
        });
      }, false);
    }
  }
};
</script>

<style lang="scss">


iframe {
  width: 100%;
  height: 100%;
}

.debit-pull-group {
  &--element {
    margin-bottom: 15px;

    ul {
      list-style-type: disc;
      padding-left: 20px;
      margin-top: 5px;
      color: var(--color-red);
    }
  }
}

label {
  position: relative;
  color: #8898AA;
  font-weight: 300;
  height: 40px;
  line-height: 40px;
  //margin-left: 20px;
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  border-radius: 4px;
  background: white;
}

label > span {
  width: 120px;
  text-align: right;
  margin-right: 30px;
}

.field {
  background: transparent;
  font-weight: 300;
  border: 0;
  color: #31325F;
  outline: none;
  flex: 1;
  padding-right: 10px;
  padding-left: 10px;
  cursor: text;
}

.field::-webkit-input-placeholder {
  color: #CFD7E0;
}

.field::-moz-placeholder {
  color: #CFD7E0;
}


.success,
.error {
  display: none;
  font-size: 13px;
}

.success.visible,
.error.visible {
  display: inline;
}

.error {
  color: var(--color-red);
}

.success {
  color: var(--color-green);
}

.success .token {
  font-weight: 500;
  font-size: 13px;
}

.el-button {
  color: white;
}

</style>
